import hotel1 from "../assets/img/single-page/hotel-grid1.jpg";
import { Link } from "react-router-dom";
import PriceFilter from "./helper/PriceFilter";
import { useDispatch } from "react-redux";
import {
  setFilterHotelAmenities,
  setFilterHotelId,
} from "../reducer/filterHotelReducer";
import SearchBox from "./Hotel/SearchBox";

function DestinationLeft() {
  const location = [
    {
      id: "kota",
      name: "Kota",
    },
    {
      id: "kochi",
      name: "Kochi",
    },
    {
      id: "nagpur",
      name: "Nagpur",
    },
  ];

  const hotels = [
    {
      id: "kota",
      name: "THE ECO Satva Kota"
    },
    {
      id: "kochi",
      name: "EcoValue Kochi "
    },
    {
      id: "nagpur",
      name: "EcoXpress Satva Nagpur"

    }
  ]

  const amenities = [
    { id: "la-parking", name: "Parking" },
    { id: "la-wind", name: "AC" },
    { id: "la-tv", name: "TV" },
    { id: "la-wifi", name: "Wifi" },
    { id: "la-quidditch", name: "Room service" },
    { id: "la-coffie", name: "Restaurant" },
    { id: "la-receipt", name: "Reception" },
  ];

  const dispatch = useDispatch();

  return (
    <>
      <div className="shop-sidebar-content">
        <div className="shop-close-content">
          <div className="shop-close-content-icon">
            <i className="las la-times"></i>
          </div>

          <p>Filter By </p>


          <div className="single-shop-left bg-white radius-10 mt-3">
            <div className="single-shop-left-title open">
              <h5 className="title"> Destination </h5>
              <div className="single-shop-left-inner margin-top-15">
                <ul className="single-shop-left-list active-list list-style-none verdana">
                  {/* Search Input */}
                  <li className="item-search">
                    <input
                      type="text"
                      className="form--control"
                      placeholder="Search Destination"
                    />
                    <div className="item-search-icon">
                      <i className="las la-search"></i>
                    </div>
                  </li>

                  {/* List items with checkboxes */}

                  {location.map((loc) => (
                    <li className="item" key={loc.id}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => dispatch(setFilterHotelId(loc.id))}
                        />{" "}
                        {loc.name}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="single-shop-left bg-white radius-10 mt-4">
            <div className="single-shop-left-title open">
              <h5 className="title"> Amenities </h5>
              <div className="single-shop-left-inner margin-top-15">
                <ul className="single-shop-left-list active-list list-style-none pat-20 verdana">
                  {amenities.map((amenity) => (
                    <li className="item" key={amenity.id}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={() =>
                            dispatch(setFilterHotelAmenities(amenity.id))
                          }
                        />{" "}
                        {amenity.name}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <PriceFilter /> */}
        </div>
      </div>
    </>
  );
}

export default DestinationLeft;
