import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const SearchBox = ({ backBtn = true }) => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const locations = [
    { id: "kota", name: "Kota", url: "/HotelDetails" },
    { id: "kochi", name: "Kochi", url: "/KochiHotelDetails" },
    { id: "nagpur", name: "Nagpur", url: "/NagpurHotelDetails" },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        maxWidth: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: 2,
      }}
    >
      {/* Back button */}
      {
        backBtn && (

          <IconButton onClick={() => window.history.back()} color="#8b4513">
            <ArrowBackIcon fontSize="large" color="#8b4513" />
          </IconButton>

        )
      }
      {/* Searchable Dropdown */}
      <Autocomplete
        options={locations}
        getOptionLabel={(option) => option.name}
        value={
          inputValue ? locations.find((loc) => loc.name === inputValue) : null
        }
        onChange={(event, selectedLocation) => {
          if (selectedLocation) {
            navigate(selectedLocation.url);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Choose your Destination"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <IconButton>
                  <SearchIcon color="#8b4513" />
                </IconButton>
              ),
            }}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                borderRadius: "10rem",
                height: "3rem",
              },
            }}
          />
        )}
        sx={{
          width: "100%",
          "& .MuiAutocomplete-inputRoot": {},
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
            "&:hover fieldset": {
              borderColor: "#8b4513",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#8b4513",
            },
          },
        }}
      />
    </Box>
  );
};

export default SearchBox;
