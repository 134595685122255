import React, { useState, useEffect } from "react";
import axios from "../lib/axiosConfig";
import { CheckCircle } from "lucide-react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Subscription from "./helper/Subscription";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CircleX } from "lucide-react";

const PaymentSuccessPage = () => {
  const [animate, setAnimate] = useState(false);
  const [searchParam] = useSearchParams();
  const [submitDetails, setSubmitDetails] = useState();
  const [paymentData, setPaymentData] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [checkoutString, setCheckoutString] = useState("");

  useEffect(() => {
    setLoading(true);
    setMessage("Verifying Payment");

    const fetchBookingBlockData = () => {
      const bookingBlockData = localStorage.getItem("bookingBlockData");
      const sendPaymentData = localStorage.getItem("sendPaymentData");
      const checkoutString = sessionStorage.getItem("checkoutString");

      if (bookingBlockData) {
        setSubmitDetails(JSON.parse(bookingBlockData));
      }

      if (sendPaymentData) {
        setPaymentData(JSON.parse(sendPaymentData));
      }

      if (checkoutString) {
        setCheckoutString(checkoutString);
      }
      setLoading(false);
    };

    fetchBookingBlockData();
  }, []);

  useEffect(() => {
    if (!submitDetails || !paymentData) {
      // Prevent callback from running until both submitDetails and paymentData are available
      setLoading(false);
      setIsError(true);
      return;
    }

    setAnimate(true);
    setLoading(true);

    const handlePaymentCallback = async () => {
      try {
        // Attempt to call the payment callback API
        const res = await axios({
          method: "POST",
          url: `/public/payment/payment-link/callback`,
          data: {
            transactionId: paymentData.transactionId,
          },
        });

        if (res?.data?.data?.status !== "CHARGED") {
          console.log("first");
          console.log(paymentData);
          setLoading(false);
          setIsError(true);
          return;
        }

        // If successful, proceed to confirm the booking
        const res2 = await axios({
          method: "PATCH",
          url: `/public/service-requests/room-type/${paymentData?.companyId}/${paymentData?.propertyId}/${paymentData?.serviceId}/confirm`,
          data: submitDetails,
        });

        // If confirmation is successful, show confirmation message and clear localStorage
        if (res2) {
          localStorage.removeItem("bookingBlockData");
          localStorage.removeItem("sendPaymentData");
          setIsError(false);
          setMessage("Booking confirmed");
        }
      } catch (error) {
        console.error("Payment callback failed:", error);
        setMessage(`Payment callback failed`);
        setIsError(true);
      } finally {
        // Ensure loading is stopped no matter what happens
        setLoading(false);
      }
    };

    handlePaymentCallback();
  }, [submitDetails, paymentData]);

  console.log(isError);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            zIndex: 9999,
            textAlign: "center",
          }}
        >
          <CircularProgress color="primary" />
          <Typography
            variant="h6"
            ml={2}
            sx={{
              color: "white",
            }}
          >
            {message}
          </Typography>
        </Box>
      ) : isError ? (
        // Error message
        <div className="payment-success-container">
          <div
            className={`payment-success-card ${
              animate ? "animate-fade-in-up" : ""
            }`}
          >
            <div className="content-wrapper">
              <div
                className={`icon-wrapper ${animate ? "animate-scale-in" : ""}`}
              >
                <CircleX className="failure-icon" />
              </div>
              <h1 className="title">Payment Failed!</h1>
              <p className="message">
                There was an issue processing your payment. Please try again.
              </p>
              <Link
                className="primary-button"
                to={`/checkout${checkoutString}`}
              >
                Retry Payment
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="payment-success-container">
          <div
            className={`payment-success-card ${
              animate ? "animate-fade-in-up" : ""
            }`}
          >
            <div className="content-wrapper">
              <div
                className={`icon-wrapper ${animate ? "animate-scale-in" : ""}`}
              >
                <CheckCircle className="success-icon" />
              </div>
              <h1 className="title">Payment Successful!</h1>
              <p className="text-center my-2">
                <strong>Reservation Transaction ID:</strong>{" "}
                {paymentData.transactionId}
              </p>
              <ul className="checkout-flex-list list-style-none">
                <li className="d-flex justify-content-between">
                  <span className="fw-bold fs-18 label">
                    Total: ₹{paymentData.amount}{" "}
                    <span className="fs-13 text-gold">(Including Tax)</span>
                  </span>
                </li>
              </ul>
              <p className="message">
                Thank you for your purchase. Your transaction has been completed
                successfully.
              </p>
              <p className="sub-message">
                An email confirmation has been sent to your registered email
                address.
              </p>
              <Link className="primary-button" to="/">
                Return to Home
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSuccessPage;
