import { Link } from "react-router-dom";
import Hotel1 from "../../assets/img/nagpur/hotel1.jpg";
import Hotel2 from "../../assets/img/nagpur/hotel2.png";
import Hotel3 from "../../assets/img/nagpur/hotel3.png";
import Hotel4 from "../../assets/img/nagpur/hotel2.jpg";
// import Hotel4 from "../../assets/img/nagpur/hotel4.png";

export const nagpurHotelImageList = [
  { id: 1, img: Hotel1 },
  { id: 2, img: Hotel4 },
  { id: 3, img: Hotel2 },
  { id: 4, img: Hotel3 },
  // { id: 4, image: Hotel4 },
];

export const ggContent = (
  <>
    <p>
      GG's, a small complex based at the rooftop, is created to satisfy the
      aspirations of the young and modern generation. It served Pan Asian food,
      delicious yet based upon our vegetarian philosophy. At places it’s
      combined with a dance floor where the young and the ever young can shake
      their legs. At times, and wherever space permits, GG's will also serve to
      promote art and culture and will periodically organise such events.
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-4 col-md-2">
        <p className="label">Everyday:</p>
      </div>
      <div className="col-7 col-md-6 ">
        <p className="text-gold">5:00 PM – 11:00 PM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        {/* <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link> */}
      </div>
    </div>
  </>
);

export const saharContent = (
  <>
    <p>
      Back to Roots (A PURE VEGETARIAN RESTAURANT) : Sahar Restaurant is more
      than just a dining experience—it’s a celebration of vegetarianism as a way
      of life. Inspired by the principles of Vaishnav and Jain philosophy, Sahar
      embraces the essence of non-violence (ahimsa) and ecological
      responsibility in every dish.
      <br />
      Our menu reflects a deep commitment to ethical, spiritual, and
      environmental values, offering a blend of traditional Indian recipes and
      innovative global flavors.
      <br />
      As part of Eco Hotels and Resorts, Sahar aims to promote a conscious
      lifestyle, where food choices align with deeply held beliefs about life
      and sustainability.
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-4 col-md-2">
        <p className="label">Breakfast:</p>
        <p className="label">Lunch:</p>
        <p className="label">Dinner:</p>
      </div>
      <div className="col-8 col-md-6 ">
        <p className="text-gold">7:30 AM – 10:30 AM</p>
        <p className="text-gold">12:30 PM – 3:00 PM</p>
        <p className="text-gold">7:30 PM – 10:30 PM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        {/* <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link> */}
      </div>
    </div>
  </>
);

export const kickInTheBrickContent = (
  <>
    <p>
      Kick in the Brick is the pub and bar which is created to relieve you from
      the daily stress. To ensure that teetotallers don’t loose the company of
      their near and dear ones it serves mocktails too. At places it works as a
      dance-floor too.
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-4 col-md-2">
        <p className="label">Everyday:</p>
      </div>
      <div className="col-7 col-md-6 ">
        <p className="text-gold">5:00 PM – 12:00 AM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        {/* <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link> */}
      </div>
    </div>
  </>
);
