export function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <divasa className="next-icon radius-parcent-50" onClick={onClick}>
      <i className="las la-angle-right"></i>
    </divasa>
  );
}

export function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="prev-icon radius-parcent-50" onClick={onClick}>
      <i className="las la-angle-left"></i>
    </div>
  );
}

export const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autodelayspeed: 2500,
  swipeToSlide: true,
  arrows: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const settings1 = {
  dots: true,
  infinite: true,
  autoplay: true,
  autodelayspeed: 5000,
  swipeToSlide: true,
  arrows: false,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  centerPadding: "60px",
  customPaging: (i) => <div className="custom-dot"></div>,
  dotsClass: "custom-slick-dots custom-dots",

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export const settings2 = {
  dots: true,
  infinite: true,
  autoplay: true,
  autodelayspeed: 5000,
  swipeToSlide: true,
  arrows: false,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  centerPadding: "60px",
  customPaging: (i) => <div className="custom-dot"></div>,
  dotsClass: "custom-slick-dots custom-dots",

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

