import living from "../assets/img/kochi/rooms/room1.webp";
import React from "react";
import EcoLogo from "../assets/brand/brand1.webp";
import nearby1 from "../assets/img/kochi/nearby1.png";
import nearby2 from "../assets/img/kochi/nearby2.png";
import nearby3 from "../assets/img/hotel/nearyby-3.png";
import { LivingContent } from "./content/EcoHotel";
import { kochiHotelImageList } from "./content/KochiHotel";
import HotelPageBuilder from "./ui/HotelPageBuilder";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

const livingData = [
  {
    tab: "Overview",
    content:
      "Each room features a harmonious blend of modern decor and thoughtful amenities, ensuring a restful environment for our guests. Each room is equipped with plush bedding, air conditioning, complimentary Wi-Fi, and flat-screen TVs. Large windows invite natural light, creating a warm and inviting atmosphere. Whether you're winding down after a busy day or gearing up for a new adventure in Kochi, our rooms are your perfect retreat. Experience the essence of comfort and relaxation at Eco Value.",
  },
  {
    tab: "Deluxe Room",
    content: LivingContent(
      "Our Deluxe Rooms offer the perfect blend of simplicity and modern comfort. Ideal for solo travelers or couples, these rooms provide all essential amenities in a compact and efficient layout.",
      2500
    ),
  },
  ``,
];

const aboutHotel = (
  <p>
    <strong>Welcome to Eco Value – Your Haven in Kochi</strong>
    <br />
    <br />
    Located just a short distance from the High Court, Eco Value is a charming
    16-room property designed for both business and leisure travelers. Each room
    is tastefully furnished, offering modern amenities to ensure a comfortable
    and relaxing stay.
    <br />
    <br />
    Our prime location provides easy access to key attractions, bustling
    markets, and the vibrant culture of Kochi, making it the perfect base for
    exploring the city. Whether you’re visiting for work or pleasure, our
    dedicated staff is committed to delivering exceptional service and a warm,
    welcoming atmosphere.
    <br />
    <br />
    Experience the perfect blend of comfort and convenience at Eco Value – your
    home away from home in Kochi.
  </p>
);

function KochiHotelDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sections = [
    {
      id: 1,
      label: "Living",
      image: living,
      type: "living",
      data: livingData,
      style: isMobile ? "rounded" : "oval",
    },

    { id: 4, label: "Nearby Attractions" },
  ];

  const attractions = [
    {
      name: "Fort Kochi",
      location: "Kochi",
      image: nearby1,
    },
    {
      name: "Mattancherry Palace",
      location: "Kochi",
      image: nearby2,
    },
    {
      name: "Mangalavanam",
      location: "Kochi",
      image: nearby3,
    },
  ];

  return (
    <HotelPageBuilder
      hotelName={
        <span>
          EcoValue Kochi
          <Typography color="#667085">(200 m away from Kerala High Court)</Typography>
        </span>
      }
      logo={EcoLogo}
      address="67/6446, Basin Road, Kochi - 682031"
      phone="+91 9004935763"
      email="reservations@ecohotels.in"
      amenities={[]}
      aboutHotel={aboutHotel}
      sections={sections}
      coverImages={kochiHotelImageList}
      factSheet={{}}
      nearby={attractions}
      nearbyLink="/KochiNearbyAttraction"
    />
  );
}

export default KochiHotelDetails;
