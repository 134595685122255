import React, { useState, useEffect } from "react";
import roombanner from "../../assets/img/hotel/room-banner.png";
import axios from "../../lib/axiosConfig";
import CommonBanner from "../helper/CommonBanner";
import RoomCard from "./RoomCard";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBookingData } from "../../reducer/bookingHotelReducer";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { PhoneIcon } from "lucide-react";
import EmailIcon from "@mui/icons-material/Email";

// import { availableRooms } from "../content/RoomDetails";

function HotelSearch() {
  const [availableRooms, setAvailableRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParam, setSearchParams] = useSearchParams();
  const [nextDate, setNextDate] = React.useState("");

  const selection = {
    location: {
      propertyId: searchParam.get("property"),
      companyId: searchParam.get("company"),
      propertyType: searchParam.get("propertyType"),
    },
    startDateTime: searchParam.get("checkin"),
    endDateTime: searchParam.get("checkout"),
    noOfAdult: searchParam.get("person"),
    noOfChild: searchParam.get("children"),
    noOfRooms: searchParam.get("room"),
  };
  const handleChangeParam = (key, value) => {
    const updatedParams = new URLSearchParams(searchParam);
    updatedParams.set(key, value);
    setSearchParams(updatedParams);
  };

  const fetchAvailableRooms = async () => {
    setIsLoading(true);
    try {
      const res = await axios({
        method: "POST",
        url: `/public/room-types/${selection?.location?.companyId}/${selection?.location?.propertyId}/available`,
        data: {
          noOfAdult: selection.noOfAdult,
          noOfChild: selection.noOfChild,
          startDateTime: selection.startDateTime,
          endDateTime: selection.endDateTime,
          noOfRooms: selection.noOfRooms
        },
      });

      setAvailableRooms(res.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };
  useEffect(() => {
    fetchAvailableRooms();
  }, []);

  const { property } = useSelector((state) => state.property);
  const dispatch = useDispatch();

  const propertyId = property?.["_id"];
  const name = property["name"];
  const address1 = property["address"]["address1"];
  const address2 = property["address"]["address2"];

  function storeRoomDetails(index, addOn, addOnIndex) {
    const addOnData = availableRooms[index]?.addOns[addOn]?.items[addOnIndex];
    console.log(addOnData);
    availableRooms[index].addOns = addOnData;
    dispatch(setBookingData(availableRooms[index]));
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  useEffect(() => {
    if (nextDate.trim()) {
      handleChangeParam("checkout", nextDate);
    }
  }, [nextDate]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {/* <TopBanner /> */}
      <CommonBanner banner={roombanner} />
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}

      <section className="hotel-details-area section-bg-2 pab-50">
        <div className="container">
          <div className="row g-4 pat-20">
            <div className="col-12 col-xl-9">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  <div className="details-contents-header"></div>

                  <div className="hotel-view-contents">
                    <div className="hotel-view-contents-header">
                      <Box
                        sx={{
                          p: 2,
                        }}
                      >
                        {/* Hotel Name and Logo */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          mt={2}
                        >
                          <Box item xs={10}>
                            <Typography variant="h5" fontWeight="bold">
                              {name}
                            </Typography>
                          </Box>
                        </Box>

                        <Stack>
                          <Stack
                            direction="row"
                            alignItems="start"
                            spacing={1}
                            mt={4}
                          >
                            <LocationOn fontSize="medium" />
                            <Typography variant="body">
                              <strong>Address1:</strong> {address1}
                              {address2 !== "" && (
                                <span className="pal-10">
                                  , <strong>Address2:</strong> {address2}
                                </span>
                              )}
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* Contact Information */}
                        <Stack
                          direction={isMobile ? "column" : "row"}
                          spacing={2}
                          mt={2}
                          alignItems={isMobile ? "flex-start" : "center"}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <PhoneIcon fontSize="small" />
                            <Typography variant="body">
                              +91 9004935763
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <EmailIcon fontSize="small" />
                            <Typography variant="body">
                              reservations@ecohotels.in
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>

                      <div className="bg-gray pb-1">
                        <div className="mt-5 bg-gray p-2">
                          <h5 className="fw-bold">Select a Room</h5>
                        </div>
                        {availableRooms?.length < 1 ? (
                          <p className="text-center my-5">
                            We're sorry, but it appears that no rooms are
                            available for your selected criteria. Please try
                            adjusting your search or check back later, as
                            availability may change. Thank you for your
                            understanding.
                          </p>
                        ) : (
                          availableRooms?.map((room, index) => (
                            <RoomCard
                              data={room}
                              roomIndex={index}
                              storeRoomDetails={storeRoomDetails}
                              noOfAdult={selection.noOfAdult}
                              selection={selection}
                            />
                          ))
                        )}
                        {/* <RoomCard /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-3">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-dropdown custom-form">
                      <form action="#">
                        <div className="single-input">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check In{" "}
                          </label>
                          <input
                            class="form-control p-3 label"
                            type="date"
                            placeholder="Check in"
                            value={selection.startDateTime}
                            min={
                              propertyId === "66faf38ab1e4100019b5be7a"
                                ? "2025-01-06"
                                : propertyId === "66dd3a63b6371e001996fd74"
                                ? "2024-01-01"
                                : new Date().toISOString().split("T")[0]
                            }
                            onChange={(e) => {
                              handleChangeParam("checkin", e.target.value);
                              setNextDate(
                                addDays(e.target.value, 1)
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          />
                        </div>
                        <div className="single-input mt-3">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check Out{" "}
                          </label>
                          <input
                            class="form-control p-3 label"
                            type="date"
                            placeholder="Check out"
                            value={selection.endDateTime}
                            min={nextDate}
                            onChange={(e) =>
                              handleChangeParam("checkout", e.target.value)
                            }
                          />
                        </div>
                      </form>
                    </div>
                    <div className="details-sidebar-quantity pt-4">
                      <div className="details-sidebar-quantity-flex">
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Adults{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            {/* <span className="minus">
                              <i className="las la-minus"></i>
                            </span> */}
                            <input
                              className="quantity-input"
                              type="number"
                              value={selection.noOfAdult}
                              onChange={(e) =>
                                handleChangeParam("person", e.target.value)
                              }
                            />
                            {/* <span className="plus">
                              <i className="las la-plus"></i>
                            </span> */}
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Children{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            {/* <span className="minus">
                              <i className="las la-minus"></i>
                            </span> */}
                            <input
                              className="quantity-input"
                              type="number"
                              value={selection.noOfChild}
                              onChange={(e) =>
                                handleChangeParam("children", e.target.value)
                              }
                            />
                            {/* <span className="plus">
                              <i className="las la-plus"></i>
                            </span> */}
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Room{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            {/* <span className="minus">
                              <i className="las la-minus"></i>
                            </span> */}
                            <input
                              className="quantity-input"
                              type="number"
                              value={selection.noOfRooms}
                              onChange={(e) =>
                                handleChangeParam("room", e.target.value)
                              }
                            />
                            {/* <span className="plus">
                              <i className="las la-plus"></i>
                            </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper mt-4">
                      <button
                        onClick={fetchAvailableRooms}
                        className="cmn-btn btn-bg-1 btn-small"
                      >
                        Edit Selection
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    // <>
    //   <HotelBanner />
    //   <section className="hotel-list-area section-bg-2 pat-50 pab-50">
    //     <div className="container">
    //       <div class="section-title center-text">
    //         <h2 class="title"> Hotel </h2>
    //         <div class="section-title-shapes"> </div>
    //       </div>
    //       <div className="shop-contents-wrapper mt-5">
    //         <div className="shop-icon">
    //           <div className="shop-icon-sidebar">
    //             <i className="las la-bars"></i>
    //           </div>
    //         </div>
    //         <DestinationLeft />
    //         <DestinationRight
    //           reserveType="reserve"
    //           data={availableRooms}
    //           isLoading={isLoading}
    //           url={"/Checkout"}
    //         />
    //       </div>
    //     </div>
    //   </section>
    // </>
  );
}

export default HotelSearch;
