import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchData } from "../../reducer/searchHotelReducer";
import { withStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  IconButton,
  Grid,
  Button,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RoomIcon from "@mui/icons-material/Room";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { setPropertyData } from "../../reducer/propertyReducer";

const CommonCounter = ({ title, countP, setCounter, resetTrigger }) => {
  const [count, setCount] = useState(countP);

  const handleIncrement = () => {
    if (count < 2) {
      setCount(count + 1);
    }
  };
  const handleDecrement = () => {
    if (count > 0) setCount(count - 1);
  };

  useEffect(() => {
    setCounter(count);
  }, [count]);

  // useEffect(() => {
  //   setCount(0);
  // }, [resetTrigger]);

  return (
    <Box p={1}>
      <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
        {title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="outlined"
          onClick={handleDecrement}
          sx={{
            minWidth: "40px",
            width: "40px",
            height: "40px",
            padding: 0,
            border: "1px solid #8b4513",
          }}
        >
          <RemoveIcon
            sx={{
              color: "#8b4513",
            }}
          />
        </Button>
        <Typography variant="body1" sx={{ margin: "0 10px" }}>
          {count}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleIncrement}
          sx={{
            minWidth: "40px",
            width: "40px",
            height: "40px",
            padding: 0,
            border: "1px solid #8b4513",
          }}
        >
          <AddIcon
            sx={{
              color: "#8b4513",
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};
const PersonAndRoomBlock = ({ onUpdatePersonRoomData }) => {
  const [expanded, setExpanded] = useState(false);
  const [roomData, setRoomData] = useState([
    { adult: 1, children: 0, room: 1 },
  ]);
  const totalRoom = roomData.length;

  const handleUpdateRoom = (index, key, value) => {
    const updatedRooms = [...roomData];
    updatedRooms[index][key] = value;
    setRoomData(updatedRooms);

    // Update totals
    const totalAdults = updatedRooms.reduce((sum, room) => sum + room.adult, 0);
    const totalChildren = updatedRooms.reduce(
      (sum, room) => sum + room.children,
      0
    );
    onUpdatePersonRoomData(totalAdults, totalChildren, updatedRooms.length);
  };

  const addRooms = () => {
    setRoomData([...roomData, { adult: 1, children: 0, room: totalRoom + 1 }]);
    onUpdatePersonRoomData(
      roomData.reduce((sum, room) => sum + room.adult, 0) + 1,
      roomData.reduce((sum, room) => sum + room.children, 0),
      totalRoom + 1
    );
  };

  const adult = roomData.reduce((sum, room) => sum + room.adult, 0);
  const child = roomData.reduce((sum, room) => sum + room.children, 0);

  useEffect(() => {
    onUpdatePersonRoomData(adult, child, roomData.length);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #8b4513",
          borderRadius: "5px",
          height: "56px",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography sx={{ pl: "14px", pr: "30px", color: "#B8860B" }}>
          Room(s) {totalRoom} - Adult(s) {adult} - Children(s) {child}
        </Typography>
        <Box sx={{ mr: "5px" }}>
          <ArrowDropDownIcon />
        </Box>
      </Box>
      {expanded && (
        <Box
          sx={{
            position: "absolute",
            width: "405px",
            zIndex: 1,
            backgroundColor: "white",
            borderRadius: "5px",
            boxShadow: 1,
            mt: "5px",
            border: "1px solid #ccc",
          }}
        >
          {roomData?.map((item, index) => (
            <Box key={index}>
              <Typography sx={{ p: "10px" }}>Room: {index + 1}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  p: "10px",
                }}
              >
                <CommonCounter
                  title="Adult(s):"
                  countP={item.adult}
                  setCounter={(value) =>
                    handleUpdateRoom(index, "adult", value)
                  }
                  initialValue={item.adult}
                />
                <CommonCounter
                  countP={item.children}
                  title="Children(s):"
                  setCounter={(value) =>
                    handleUpdateRoom(index, "children", value)
                  }
                  initialValue={item.children}
                />

                {
                  // Hide remove button for the first room
                  index !== 0 && (
                    <Button
                      onClick={() => {
                        const newRoomData = roomData.filter(
                          (_, idx) => idx !== index
                        );
                        setRoomData(newRoomData);
                        const totalAdults = newRoomData.reduce(
                          (sum, room) => sum + room.adult,
                          0
                        );
                        const totalChildren = newRoomData.reduce(
                          (sum, room) => sum + room.children,
                          0
                        );
                        onUpdatePersonRoomData(
                          totalAdults,
                          totalChildren,
                          newRoomData.length
                        );
                      }}
                    >
                      Remove
                    </Button>
                  )
                }
              </Box>
            </Box>
          ))}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
              borderTop: "1px solid #ccc",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "#8b4513",
                color: "white",
              }}
              onClick={addRooms}
            >
              Add Room
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

const CssTextField = withStyles({
  root: {
    "& label": {
      color: "#8b4513",
      fontFamily: "var(--verdana-font)",
    },
    "& label.Mui-focused": {
      color: "#8b4513",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#8b4513",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#8b4513",
      },
      "&:hover fieldset": {
        borderColor: "#8b4513",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#8b4513",
      },
    },
    "& .MuiInputBase-input": {
      color: "#B8860B",
      fontFamily: "var(--verdana-font)",
    },
  },
})(TextField);

const withDateRestrictions = (Component, propertyId, nextDate) => {
  return (props) => {
    const customInputProps =
      propertyId === "66faf38ab1e4100019b5be7a"
        ? { min: "2025-01-06" }
        : propertyId === "66dd3a63b6371e001996fd74"
        ? { min: "2025-01-01" }
        : { min: new Date().toISOString().split("T")[0] };

    return (
      <Component
        {...props}
        inputProps={{ ...props.inputProps, ...customInputProps }}
      />
    );
  };
};

const SearchEngine = ({ properties, onChangeSelection, selection }) => {
  const dispatch = useDispatch();
  const [isValidate, setIsValidate] = useState(false);
  const [nextDate, setNextDate] = React.useState("");
  const [expanded, setExpanded] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("Select Location");
  const dropdownRef = useRef(null);

  const { property } = useSelector((state) => state.property);
  const propertyId = property?.["_id"];

  const DecoratedDateField = withDateRestrictions(
    CssTextField,
    propertyId,
    nextDate
  );

  const handleLocationSelect = (item) => {
    setSelectedLocation(item.name);
    setExpanded(false);

    dispatch(setPropertyData(item));

    console.log(item, "selected location");

    onChangeSelection("location", {
      propertyId: item._id,
      companyId: item.companyId,
      propertyType: item.name,
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  useEffect(() => {
    const initialCheckInDate =
      propertyId === "66faf38ab1e4100019b5be7a"
        ? "2025-01-06"
        : propertyId === "66dd3a63b6371e001996fd74"
        ? "2025-01-01"
        : new Date().toISOString().split("T")[0];
    onChangeSelection("checkIn", initialCheckInDate);
    setNextDate(addDays(initialCheckInDate, 1).toISOString().split("T")[0]);
  }, [propertyId]);

  useEffect(() => {
    onChangeSelection("checkOut", nextDate);
  }, [nextDate]);

  const updatePersonRoomData = (adults, children, rooms) => {
    onChangeSelection("person", adults);
    onChangeSelection("children", children);
    onChangeSelection("room", rooms);
  };

  function handleSearch() {
    dispatch(setSearchData(selection));
  }

  useEffect(() => {
    setIsValidate(
      !!selection?.location?.propertyId &&
        !!selection?.location?.companyId &&
        !!selection?.checkIn &&
        !!selection?.checkOut &&
        selection?.person >= 1
    );
  }, [selection]);

  useEffect(() => {
    setNextDate(
      addDays(new Date().toISOString().split("T")[0], 1)
        .toISOString()
        .split("T")[0]
    );
  }, []);

  return (
    <div className="container">
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mt: 2,
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <Box ref={dropdownRef} sx={{ width: "100%", position: "relative" }}>
              {/* Custom dropdown trigger */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #8b4513",
                  borderRadius: "5px",
                  height: "56px",
                  paddingLeft: "14px",
                  cursor: "pointer",
                }}
                onClick={() => setExpanded(!expanded)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RoomIcon sx={{ mr: 1 }} className="label" />
                  <Typography className="text-gold">
                    {selectedLocation}
                  </Typography>
                </Box>
                <ArrowDropDownIcon sx={{ mr: "5px" }} />
              </Box>

              {/* Dropdown menu */}
              {expanded && (
                <Box
                  sx={{
                    display: expanded ? "block" : "none",
                    position: "absolute",
                    width: "100%",
                    zIndex: 10,
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: 5,
                    mt: "5px",
                    border: "1px solid #ccc",
                  }}
                >
                  {properties?.map((item) => (
                    <Box
                      key={item?._id}
                      onClick={() => handleLocationSelect(item)}
                      sx={{
                        padding: "10px 16px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                    >
                      <Typography>{item?.name}</Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <DecoratedDateField
                label="Check In"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={selection?.checkIn}
                onChange={(e) => {
                  onChangeSelection("checkIn", e.target.value);
                  setNextDate(
                    addDays(e.target.value, 1).toISOString().split("T")[0]
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <CssTextField
                label="Check Out"
                type="date"
                value={selection?.checkOut}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: nextDate,
                }}
                onChange={(e) => onChangeSelection("checkOut", e.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <PersonAndRoomBlock onUpdatePersonRoomData={updatePersonRoomData} />
          </Grid>

          <Grid item xs={12} md={1}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {isValidate ? (
                <Link
                  onClick={handleSearch}
                  to={`/HotelSearch?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.checkIn}&checkout=${selection?.checkOut}&person=${selection?.person}&children=${selection?.children}&room=${selection?.room}&propertyType=${selection?.location?.propertyType}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#8b4513",
                      color: "white",
                      height: "54px",
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Link>
              ) : (
                <IconButton
                  disabled
                  size="large"
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[300],
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default SearchEngine;
